// @ts-nocheck
// @ts-ignore
// eslint-disable-next-line no-unused-expressions
/*
    Copyright 2023 AShield Technologies

    Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

    The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

    THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
// import logger from "./logger";
const logger = console;

function arrayBufferToString(arrayBuffer) {
  return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
}
// function for cahnging to ArrayBuffer
function toArrayBuffer(e) {
  const t = e.replace(/-/g, "+").replace(/_/g, "/"),
    n = (4 - (t.length % 4)) % 4,
    r = t.padEnd(t.length + n, "="),
    o = atob(r),
    a = new ArrayBuffer(o.length),
    i = new Uint8Array(a);
  for (let e = 0; e < o.length; e++) i[e] = o.charCodeAt(e);
  return a;
}

// function for changing ArrayBuffer to base64
function arrayBufferToBase64(e) {
  const t = new Uint8Array(e);
  let n = "";
  for (const e of t) n += String.fromCharCode(e);
  return btoa(n).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

function idStringToArrayBuffer(e) {
  for (let i = 0; i < e.length; i++) {
    e[i].id = toArrayBuffer(e[i].id);
  }
  return e;
}
var serverBaseUrl = "";
var algo = "";
/*sbUrl : Server URL from which the request will be proxied
algo : Alogrithm same which will be decided from server
*/
export async function init(sbUrl, alg) {
  algo = alg;
  serverBaseUrl = sbUrl;
}
// Register function: takes values from the user
/* un is nothing the userId which would be registered and uniquely identified
mid : MerchantID provided by AShield team
merTxnId : TransactionID created for this request for further reference
onError and onSuccess or just callback functions invoked accordingly 
*/
export async function registerUser(
  un,
  mid,
  merTxnId,
  cusHeaders,
  onError,
  onSuccess
) {
  let msg = "Unexpected error";
  // Data passing for preregister
  try {
    //Update UI to reflect availability of platform authenticator
    /*if (PublicKeyCredential && typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable !== "function") {
      onError("Platform Authenticator Unavailable");
      return;
    } else if (PublicKeyCredential && typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable === "function") {
      PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then(available => {
          if (!available) {
              onError("Platform Authenticator Unavailable");
              return;
          }
      }).catch(e=>{
          onError("Platform Authenticator Unavailable");
          return;
      });
    }*/
    let status = 0;
    const preRegisterData = {
      un: un,
      mid: mid,
      merTxnId: merTxnId,
      origin: window.location.hostname,
    };

    let headers = {
      "Content-Type": "application/json",
    };
    Object.assign(headers, cusHeaders);
    const preregisterResponse = await fetch(serverBaseUrl + "/preregister", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(preRegisterData),
    });
    // getting preregisterResponse
    const preRegisterResponseData = await preregisterResponse.json();

    if (!preregisterResponse.ok) {
      logger.trace(
        "Pre-register response: " + JSON.stringify(preregisterResponse)
      );
      onError("CL_ERR_1001", "Invalid Registration request");
      return;
      //throw new Error("Failed to pre-register user");
    }

    msg = preRegisterResponseData.msg;
    status = preRegisterResponseData.status;
    if (status == 4002) {
      // Show error message
      onError("CL_ERR_1002", "Unable to process Registration request");
      return;
    }
    // parsing the preregisterResponse
    const preRegisterResponse = JSON.parse(preRegisterResponseData.fido);

    logger.trace("Pre-register Response:", preRegisterResponse.Response);

    const rpName = preRegisterResponse.Response.rp.name;
    const challenge = preRegisterResponse.Response.challenge;
    const pubKeyCredParams = preRegisterResponse.Response.pubKeyCredParams;
    const excludeCredentials = idStringToArrayBuffer(
      preRegisterResponse.Response.excludeCredentials
    );
    // const attestation = preRegisterResponse.Response.attestation;
    const userId = preRegisterResponse.Response.user.id;
    const unf = preRegisterResponse.Response.user.name;

    logger.trace("Prepare PK options");
    // Options to pass in the WebAuthn API
    const publicKeyOptions = {
      challenge: toArrayBuffer(challenge),
      rp: {
        name: rpName,
        id: preRegisterResponse.Response.rp.id,
      },
      user: {
        id: toArrayBuffer(userId),
        name: unf,
        displayName: unf,
      },
      pubKeyCredParams: pubKeyCredParams,
      excludeCredentials: excludeCredentials,
      attestation: "direct",
      timeout: 60000,
      authenticatorSelection: {
        authenticatorAttachment: "platform",
        requireResidentKey: false,
        userVerification: "required",
      },
    };

    logger.trace("Create Credential:", publicKeyOptions);
    // Call the WebAuthn API to create the credential
    const credential = await navigator.credentials.create({
      publicKey: publicKeyOptions,
    });
    logger.trace("Credential Response:", credential);

    // Data passing for register
    localStorage.setItem("Credential ID: ", credential.id);

    let epuk = localStorage.getItem(un + "_" + "epuk");
    let epik = localStorage.getItem(un + "_" + "epik");

    if (null == epuk || 0 == epuk.length) {
      // Create public-private key pair
      try {
        let keypar = await window.crypto.subtle.generateKey(
          {
            name: "RSA-OAEP",
            modulusLength: 2048,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: algo,
          },
          true,
          ["encrypt", "decrypt"]
        );
        let ek = await window.crypto.subtle.exportKey("spki", keypar.publicKey);
        epuk = arrayBufferToBase64(ek);
        localStorage.setItem(un + "_" + "epuk", epuk);

        let epk = await window.crypto.subtle.exportKey(
          "pkcs8",
          keypar.privateKey
        );

        epik = arrayBufferToBase64(epk);
        localStorage.setItem(un + "_" + "epik", epik);
        localStorage.setItem(un + "_" + "cid", credential.id);
      } catch (exp) {
        // Throw error
        logger.error("Error occurred during registration:", exp);
        onError("CL_ERR_1003", "Client Error. " + exp.message);
        return;
      }
    }
    localStorage.setItem(un + "_" + "cid", credential.id);
    epuk = localStorage.getItem(un + "_" + "epuk");
    // PUK Must be sent as part of request
    // So must wait for above promises
    const credentialData = {
      id: credential.id,
      rawId: arrayBufferToBase64(credential.rawId),
      response: {
        attestationObject: arrayBufferToBase64(
          credential.response.attestationObject
        ),
        clientDataJSON: arrayBufferToBase64(credential.response.clientDataJSON),
      },
      type: credential.type,
      un: un,
      epuk: epuk,
      df: credential.id,
      mid: mid,
      merTxnId: merTxnId,
      origin: window.location.hostname,
    };

    let registerResponse = await fetch(serverBaseUrl + "/register", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(credentialData),
    });

    let serverRegisterResponse = await registerResponse.json();
    msg = serverRegisterResponse.msg;
    status = serverRegisterResponse.status;

    if (!registerResponse.ok) {
      logger.info("Registeration failed!");
      logger.trace(
        "Server response: " + JSON.stringify(serverRegisterResponse)
      );
      onError(
        "CL_ERR_1004",
        "Registration failed.  Unable to process Registration request"
      );
      return;
    }
    if (status == 4102) {
      // Show error message
      onError(
        "CL_ERR_1004",
        "Registration failed. Unable to process Registration request"
      );
      return;
    }
    logger.info("Registration successful!");
    // Share must be in encrypted format
    // Decrypt and store it
    if (serverRegisterResponse.resp) {
      processAsResp(un, JSON.parse(serverRegisterResponse.resp));
    }
    logger.trace("Server response: " + JSON.stringify(serverRegisterResponse));
    onSuccess("Registered");
  } catch (error) {
    logger.error("Error occurred during registration:", error);
    onError("CL_ERR_1003", "Client Error. " + error.message);
    // onError(error.message);
  }
}

// Authenticate the user

// AuthenticateUser function: takes values from the user
/* un is the userId which was registered earlier and uniquely identified
userVerification : "required/discouraged" must be passed
mid : MerchantID provided by AShield team
merTxnId : TransactionID created for this request for further reference
onError and onSuccess or just callback functions invoked accordingly 
*/
export async function authenticateUser(
  un,
  userVerification,
  mid,
  merTxnId,
  cusHeaders,
  onError,
  onSuccess
) {
  // Generate a challenge (random string)
  try {
    if (userVerification != "required" && userVerification != "discouraged") {
      // onError("Invalid user verification option");
      onError(
        "CL_ERR_2001",
        "Invalid Input. Unsupported user verification type"
      );
      return;
    }
    let headers = {
      "Content-Type": "application/json",
    };
    Object.assign(headers, cusHeaders);
    let msg = "Unexpected Error";
    let status = 0;
    const preAuthenticateData = {
      un: un,
      mid: mid,
      merTxnId: merTxnId,
      origin: window.location.hostname,
    };
    let silent = userVerification == "discouraged";
    let cid = localStorage.getItem(un + "_" + "cid");
    let asAuthData = {
      share: localStorage.getItem(un + "_" + "share"),
      txnId: localStorage.getItem(un + "_" + "txnId"),
      un: un,
      df: cid,
      mid: mid,
      merTxnId: merTxnId,
      origin: window.location.hostname,
    };
    if (silent) {
      let epuk = localStorage.getItem(un + "_" + "epuk");
      let epik = localStorage.getItem(un + "_" + "epik");
      if (null == epuk || null == epik) {
        // Unexpected error throw exception
        onError(
          "CL_ERR_2002",
          "Invalid Input. Silent Verification not supported."
        );
        //  onError("User verification is must. Unexcepted error");
        return;
      } else {
        asAuthData.silentAuth = true;
        // Perform the server authenticate request
        let sresp = await fetch(serverBaseUrl + "/authenticate", {
          method: "POST",
          headers: headers,
          body: JSON.stringify(asAuthData),
        });
        let serverAuthenticateResponse = await sresp.json();
        msg = serverAuthenticateResponse.msg;
        status = serverAuthenticateResponse.status;

        if (!sresp.ok) {
          logger.info("Authentication failed!");
          logger.trace(
            "Server Authentication Response: " +
              JSON.stringify(serverAuthenticateResponse)
          );
          onError(
            "CL_ERR_2003",
            "Silent Authentication Failed. Try biometric verification."
          );

          return;
        }
        if (status == 4302) {
          onError(
            "CL_ERR_2003",
            "Silent Authentication Failed. Try biometric verification."
          );
          return;
        }
        if (serverAuthenticateResponse.resp) {
          processAsResp(un, JSON.parse(serverAuthenticateResponse.resp));
        }
        onSuccess("Authenticated");
        return;
      }
    }

    const preauthenticateResponse = await fetch(
      serverBaseUrl + "/preauthenticate",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(preAuthenticateData),
      }
    );
    const preAuthenticateResponseData = await preauthenticateResponse.json();

    msg = preAuthenticateResponseData.msg;
    status = preAuthenticateResponseData.status;
    if (!preauthenticateResponse.ok) {
      logger.trace(
        "Pre-authenticate response" + JSON.stringify(preauthenticateResponse)
      );

      onError("CL_ERR_2004", "Invalid Authentication request");
      return;
    }

    if (status == 4202) {
      onError("CL_ERR_2004", "Invalid Authentication request");
      return;
    }
    const preAuthenticateResponse = JSON.parse(
      preAuthenticateResponseData.fido
    );
    logger.trace("Pre-authenticate Response: ", preAuthenticateResponse);

    const challenge = preAuthenticateResponse.Response.challenge;
    const allowCredentials = idStringToArrayBuffer(
      preAuthenticateResponse.Response.allowCredentials
    );

    // Create a new PublicKeyCredentialRequestOptions object
    const authenticationOptions = {
      challenge: toArrayBuffer(challenge),
      timeout: 60000,
      rpId: preAuthenticateResponse.Response.rpId,
      allowCredentials: allowCredentials,
      userVerification: userVerification,
    };

    // Call the WebAuthn API to get the assertion
    const authenticationCredential = await navigator.credentials.get({
      publicKey: authenticationOptions,
    });

    logger.trace("Credential Response:", authenticationCredential);

    // Convert the credential data to base64 and send it to the server
    const authenticationCredentialData = {
      id: authenticationCredential.id,
      rawId: arrayBufferToBase64(authenticationCredential.rawId),
      response: {
        clientDataJSON: arrayBufferToBase64(
          authenticationCredential.response.clientDataJSON
        ),
        userHandle: arrayBufferToBase64(
          authenticationCredential.response.userHandle
        ),
        signature: arrayBufferToBase64(
          authenticationCredential.response.signature
        ),
        authenticatorData: arrayBufferToBase64(
          authenticationCredential.response.authenticatorData
        ),
      },
      type: authenticationCredential.type,
      un: un,
      cid: authenticationCredential.id,
      origin: window.location.hostname,
    };
    Object.assign(authenticationCredentialData, asAuthData);
    authenticationCredentialData.df = authenticationCredential.id;
    let authenticateResponse = await fetch(serverBaseUrl + "/authenticate", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(authenticationCredentialData),
    });

    const serverAuthenticateResponse = await authenticateResponse.json();

    msg = serverAuthenticateResponse.msg;
    status = serverAuthenticateResponse.status;
    if (!authenticateResponse.ok) {
      logger.info("Authentication failed!");
      logger.trace(
        "Server Authentication Response: " +
          JSON.stringify(serverAuthenticateResponse)
      );

      onError("CL_ERR_2005", "Authentication failed. Try again");
      return;
    }

    if (status == 4302) {
      onError("CL_ERR_2005", "Authentication failed. Try again");
      return;
    }
    if (serverAuthenticateResponse.resp) {
      processAsResp(un, JSON.parse(serverAuthenticateResponse.resp));
    }
    logger.info("Authentication successful!");
    logger.trace(
      "Server Authententication Response: " +
        JSON.stringify(serverAuthenticateResponse)
    );
    onSuccess("Authenticated");
  } catch (error) {
    logger.error("Error occurred during authentication:", error);
    onError("CL_ERR_2006", "Client Error. " + error.message);
  }
}

function processAsResp(un, resp) {
  if ("eshare" in resp) {
    let encdata = resp.eshare;
    let epik = localStorage.getItem(un + "_" + "epik");
    if (undefined == epik || 0 == epik.length) {
      return;
    }
    window.crypto.subtle
      .importKey(
        "pkcs8",
        toArrayBuffer(epik),
        { name: "RSA-OAEP", hash: { name: algo } },
        true,
        ["decrypt"]
      )
      .then(function (pk) {
        window.crypto.subtle
          .decrypt("RSA-OAEP", pk, toArrayBuffer(encdata))
          .then(function (decdata) {
            let share = arrayBufferToString(decdata);
            localStorage.setItem(un + "_" + "txnId", resp.asTxnId);
            localStorage.setItem(un + "_" + "share", share);
          });
      });
  }
}
